<template>
	<div id="detail" v-title :data-title="'三特科技-'+software.title">
		<FHeader></FHeader>
		<el-carousel :autoplay="false" indicator-position="none" arrow="never" :height="bannerHeight + 'px'" :width="bannerWidth + 'px'">
			<el-carousel-item>
				<img src="../../assets/fImage/detail.png" ref="bannerImg" @load="imgLoad" style="width: 1920px; height: 450px;">
			</el-carousel-item>
		</el-carousel>
		<div class="tip"><span>详情介绍</span></div>
		<div class="back">
			<el-page-header content="列表详情" @back="goBack"/>
		</div>
		<article>
			<div class="articleBox">
				<div class="title">
					<span class="text">{{software.title}}</span>
					<span class="time">发布时间：{{software.publish}}</span>
				</div>
				<div class="desp" v-if="software.description!=''"><span>简述：{{software.description}}</span></div>
				<div class="image" v-if="software.soft_image!=''">
					<img :src="software.soft_image">
				</div>
				<div class="con_text">
					<v-md-preview :text="software.content"></v-md-preview>
				</div>
			</div>
		</article>
		<Footer></Footer>
	</div>
</template>

<script>
	import FHeader from "../../components/f/FHeader.vue"
	import Footer from "../../components/f/Footer.vue"
	import {getSoftware} from "@/api/front";

	export default {
		name: "Index",
		components: {
			FHeader,
			Footer
		},
		data() {
			return {
				software: {},
				bannerHeight: '', // 图片父容器高度
				bannerWidth: '', //图片父容器宽度
			}
		},
		created() {
			getSoftware(this.$route.query.id).then(data => {
				this.software = data.data
			})
		},
		beforeRouteUpdate(to, from, next) {
			console.log(to.params)
			next()
			
		},
		mounted() {
			this.imgLoad();
		},
		methods: {
			imgLoad() {
				this.$nextTick(() => {
					this.bannerHeight = this.$refs.bannerImg.height;
					this.bannerWidth = this.$refs.bannerImg.width;
				})
			},
			goBack(){
				this.$router.push({
					path: "/list_detail/"+this.$route.query.channelId,
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	#detail{
		.tip{
			position: absolute;
			z-index:5;
			color: #FFFFFF;
			top: 0;
			width: 100%;
			height: 100px;
			margin: 200px 0 150px 0;
			display: flex;
			justify-content: center;
			span{
				display: block;
				width: 250px;
				height: 100px;
				text-align: center;
				font-size: 40px;
				font-family: Adobe Heiti Std R;
				color: #FFFFFF;
				line-height: 100px;
			}
		}
		.back{
			height: 80px;
			padding: 0 18%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			border-bottom: 1px solid #DBDBDB;
			::v-deep .el-page-header__content {
				font-size: 24px !important;
			}
		}
		.articleBox{
			padding: 50px 18%;
			.title{
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin-bottom: 30px;
				.text{
					width: 100%;
					height: 60px;
					font-size: 36px;
					text-align: center;
					font-family: Adobe Heiti Std R;
					color: #333333;
					line-height: 60px;
				}
				.time{
					width: 100%;
					height: 60px;
					font-size: 24px;
					text-align: center;
					font-family: Adobe Heiti Std R;
					color: #666666;
					line-height: 60px;
				}
				
			}
			.desp{
				padding: 20px;
				span{
					font-size: 20px;
					font-family: Adobe Heiti Std R;
					line-height: 36px;
				}
			}
			.image{
				display: flex;
				justify-content: center;
				img{
					margin: 30px 0;
					width: 400px;
					height: 520px;
				}
			}
		}
	}
</style>
