import { render, staticRenderFns } from "./SoftwareDetail.vue?vue&type=template&id=741b1b84&scoped=true&"
import script from "./SoftwareDetail.vue?vue&type=script&lang=js&"
export * from "./SoftwareDetail.vue?vue&type=script&lang=js&"
import style0 from "./SoftwareDetail.vue?vue&type=style&index=0&id=741b1b84&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "741b1b84",
  null
  
)

export default component.exports